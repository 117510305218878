define("ember-redirect/utils/container", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.lookup = lookup;
	exports.register = register;
	function lookup(instance) {
		for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
			args[_key - 1] = arguments[_key];
		}

		if (instance.lookup) {
			return instance.lookup.apply(instance, args);
		}

		return instance.container.lookup.apply(instance.container, args);
	}

	function register(instance) {
		for (var _len2 = arguments.length, args = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
			args[_key2 - 1] = arguments[_key2];
		}

		if (instance.register) {
			return instance.register.apply(instance, args);
		}

		return instance.registry.register.apply(instance.registry, args);
	}
});