define("@ember-data/serializer/transform", ["exports", "@ember-data/serializer/transforms/boolean", "@ember-data/serializer/transforms/date", "@ember-data/serializer/transforms/number", "@ember-data/serializer/transforms/string", "@ember-data/serializer/transforms/transform"], function (_exports, _boolean, _date, _number, _string, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "BooleanTransform", {
    enumerable: true,
    get: function get() {
      return _boolean.default;
    }
  });
  Object.defineProperty(_exports, "DateTransform", {
    enumerable: true,
    get: function get() {
      return _date.default;
    }
  });
  Object.defineProperty(_exports, "NumberTransform", {
    enumerable: true,
    get: function get() {
      return _number.default;
    }
  });
  Object.defineProperty(_exports, "StringTransform", {
    enumerable: true,
    get: function get() {
      return _string.default;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _transform.default;
    }
  });
});